import { DashboardCard } from '@/components/DashboardCard';
import { TableUsers } from '@/pages/UsersList/TableUsers';
import { TableMenuActions, TableSort } from '@/types';
import { User, UserFields, FirmsDTO } from '@types';
import { Checkbox, Input } from 'react-daisyui';
import { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { getNewSortStateTable } from '@/utils/tableSort';
import { TableSwitcher } from '@/components/Table/TableSwitcher';
import { TableFirms } from '@/pages/UsersList/TableFirms';
import { FirmRows } from '@/pages/UsersList/TableFirms.util';
import SideModal from '@/components/SideModal/SideModal';
import { FirmDetails } from '@/components/FirmDetails/FirmsDetails';

interface Props {
  users: User[];
  firms: FirmsDTO[];
  // eslint-disable-next-line react/no-unused-prop-types
  test?: boolean;
  userActions: TableMenuActions;
  mutateFirms: () => Promise<void>;
}

export function UserListManagement({
  users,
  firms,
  test,
  userActions,
  mutateFirms,
}: Props) {
  const [usersSort, setUsersSort] = useState<TableSort<User>>({
    field: 'last_name',
    asc: true,
  });
  const [firmsSort, setFirmsSort] = useState<TableSort<FirmRows>>({
    field: 'firm_name',
    asc: true,
  });
  const [searchText, setSearchText] = useState<string>('');
  const [showClients, setShowClients] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
  const [selectedTable, setSelectedTable] = useState<string>('Users');
  const [showFirmModal, setShowFirmModal] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState<FirmsDTO | null>(null);

  const tableOptions = [
    { value: 'Users', label: 'Users' },
    { value: 'Firms', label: 'Firms' },
  ];

  const sortUsers = (): User[] =>
    users
      ?.sort((c1, c2) => {
        const obj1 =
          usersSort.field === 'clients'
            ? c1[usersSort.field]?.length || 0
            : c1[usersSort.field as UserFields];
        const obj2 =
          usersSort.field === 'clients'
            ? c2[usersSort.field]?.length || 0
            : c2[usersSort.field as UserFields];
        if (obj1 && obj2 && obj1 > obj2) return usersSort.asc ? 1 : -1;
        return usersSort.asc ? -1 : 1;
      })
      .sort((a, b) => {
        if (a.enabled === b.enabled) return 0;
        return a.enabled ? -1 : 1;
      })
      .filter((user) =>
        `${user.last_name?.toLowerCase()} ${user.first_name?.toLowerCase()}`.match(
          searchText.toLowerCase()
        )
      )
      .filter((user) => {
        if (!showClients) {
          return user.role !== 'client';
        }

        return true;
      });

  const sortFirms = (): FirmsDTO[] => {
    return [...firms]
      .sort((a, b) => {
        const aValue = a.firm_name;
        const bValue = b.firm_name;

        if (aValue > bValue) return firmsSort.asc ? 1 : -1;
        if (aValue < bValue) return firmsSort.asc ? -1 : 1;
        return 0;
      })
      .filter((firm) =>
        firm.firm_name?.toLowerCase().match(searchText.toLowerCase())
      );
  };

  const sortUsersBy = (fieldName: keyof User) => {
    const newSort = getNewSortStateTable(fieldName, usersSort);
    setUsersSort(newSort);
  };

  const sortedUsers = useMemo(
    () => sortUsers(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersSort, selectedPage, searchText, users, showClients]
  );

  useEffect(() => {
    setSelectedPage(1);
  }, [usersSort, recordsPerPage, searchText]);

  const sortFirmsBy = (fieldName: keyof FirmRows) => {
    const newSort = getNewSortStateTable(fieldName, firmsSort);
    setFirmsSort(newSort);
  };

  const sortedFirms = useMemo(
    () => sortFirms(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firmsSort, selectedPage, searchText, firms]
  );

  const handleFirmRowClick = (row: FirmRows) => {
    const firmFound = sortedFirms.find((f) => f.id === row.id);
    // console.log(firmFound);
    if (firmFound) {
      setSelectedFirm(firmFound);
      setShowFirmModal(true);
    }
  };

  useEffect(() => {
    setSelectedPage(1);
  }, [usersSort, recordsPerPage, searchText]);

  useEffect(() => {
    if (selectedFirm) {
      const updatedFirm = firms.find((f) => f.id === selectedFirm.id) || null;
      setSelectedFirm(updatedFirm);
    }
  }, [firms, selectedFirm]);

  return (
    <DashboardCard className='p-4' cardBodyclassName='p-0' hasMininumWidth>
      <div className='mt-3 mb-1'>
        <div className='relative text-gray-600 focus-within:text-gray-400'>
          <span className='absolute inset-y-0 left-[260px] flex items-center pl-2'>
            <button
              type='submit'
              className='p-1 focus:outline-none focus:shadow-outline'
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </span>
          <Input
            data-testid='users-search-input'
            className='border-accent w-[300px]'
            placeholder='Search Users'
            size='sm'
            defaultValue={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
          <span className='text-white ml-10'>
            Total{' '}
            {selectedTable === tableOptions[0].value
              ? tableOptions[0].value
              : tableOptions[1].value}
            :{' '}
            <strong>
              {selectedTable === tableOptions[0].value
                ? sortedUsers.length
                : sortedFirms.length}
            </strong>
          </span>
          {selectedTable === tableOptions[0].value ? (
            <>
              <span className='ml-10 text-white'>Show clients</span>
              <Checkbox
                checked={showClients}
                data-testid='showClients'
                className='border-accent relative top-[7px] ml-2'
                onChange={() => setShowClients(!showClients)}
              />
            </>
          ) : null}
          <span className='ml-10 text-white'>Table: </span>
          <div className='inline-flex'>
            <TableSwitcher
              options={tableOptions}
              selectedValue={selectedTable}
              setSelectedValue={setSelectedTable}
            />
          </div>
        </div>
      </div>

      {selectedTable === tableOptions[0].value ? (
        <TableUsers
          sortedUsers={sortedUsers}
          test={test}
          userActions={userActions}
          sort={usersSort}
          sortBy={sortUsersBy}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          recordsPerPage={recordsPerPage}
          setRecordsPerPage={setRecordsPerPage}
          searchText={searchText}
        />
      ) : null}

      {selectedTable === tableOptions[1].value ? (
        <TableFirms
          sortedFirms={sortedFirms}
          sort={firmsSort}
          sortBy={sortFirmsBy}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          recordsPerPage={recordsPerPage}
          setRecordsPerPage={setRecordsPerPage}
          searchText={searchText}
          onFirmRowClick={handleFirmRowClick}
        />
      ) : null}

      <SideModal
        loading={false}
        open={showFirmModal}
        onClose={() => setShowFirmModal(false)}
      >
        {selectedFirm ? (
          <FirmDetails firm={selectedFirm} mutateFirms={mutateFirms} />
        ) : (
          <> None </>
        )}
      </SideModal>
    </DashboardCard>
  );
}
