import React, { useState } from 'react';
import { Tabs, Button } from 'react-daisyui';
import type { FirmsDTO, FirmsWithUserDTO } from '@types';
import FirmClients from '@/components/FirmDetails/components/FirmClients/FirmClients';
import FirmUsers from '@/components/FirmDetails/components/FirmUsers/FirmUsers';
import AddClientModal from '@/components/UsersAccordionList/AddClientModal';

const { Tab } = Tabs;

interface FirmDetailsTabsProps {
  firm: FirmsDTO;
  mutateFirms: () => Promise<void>;
}

export function FirmDetails({ firm, mutateFirms }: FirmDetailsTabsProps) {
  const [activeTab, setActiveTab] = useState<'clients' | 'users'>('clients');

  const [showModalAddClient, setShowModalAddClient] = useState(false);

  return (
    <div className='p-1 max-h-[calc(100vh-100px)] overflow-y-auto'>
      <Tabs
        variant='bordered'
        value={activeTab}
        onChange={(newTab) => setActiveTab(newTab)}
      >
        <Tab value='clients' className='capitalize'>
          Clients
        </Tab>
        <Tab value='users' className='capitalize'>
          Users
        </Tab>
      </Tabs>

      {activeTab === 'clients' && (
        <div className='mt-4'>
          <Button
            type='submit'
            className='btn btn-sm btn-accent mb-4'
            onClick={() => setShowModalAddClient((prev) => !prev)}
          >
            Add Client
          </Button>
          {showModalAddClient ? (
            <AddClientModal
              firm={firm}
              isOpen={showModalAddClient}
              onClose={() => setShowModalAddClient(false)}
              mutateFirms={mutateFirms}
            />
          ) : null}

          {firm.firm_clients?.length === 0 ? (
            <div>No clients yet.</div>
          ) : (
            <FirmClients firm={firm} mutateFirms={mutateFirms} />
          )}
        </div>
      )}

      {activeTab === 'users' && (
        <div className='mt-4'>
          <FirmUsers
            firm={firm as FirmsWithUserDTO}
            mutateFirms={mutateFirms}
          />
        </div>
      )}
    </div>
  );
}
