const baseAmoebaUrl = `https://financial-analyst-demo.amoebai.com/`;

/* istanbul ignore next */
export const generateChatbotUrlWithKey = (
  teamIds: string,
  amoebaKey: string
) => {
  return `${baseAmoebaUrl}?team_ids=${teamIds}&key=${amoebaKey}`;
};

/* istanbul ignore next */
export const generateChatbotUrlWithoutKey = (teamIds: string) => {
  return `${baseAmoebaUrl}?team_ids=${teamIds}`;
};
