import { DashboardWrapper } from '@/components/DashboardWrapper';
import PageContainer from '@/components/PageContainer';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { Client } from '@types';
import { useEffect, useMemo, useState } from 'react';
import { get } from '@/utils/queries';
import {
  generateChatbotUrlWithKey,
  generateChatbotUrlWithoutKey,
} from '@/pages/Chatbot/utils';

export function Chatbot() {
  const { auth, impersonatingAccountId, companyName }: IUserContext =
    useUserContext(); // Use the context
  const { data: companyData } = auth?.isClient
    ? get<Client[]>(`account/${auth.clients[0].id}`)
    : get<Client[]>('account/list');
  const [src, setSrc] = useState('');
  const [css, setCss] = useState('');

  const clientTeams = useMemo(() => {
    const isPreProductionEnv =
      import.meta.env?.VITE_ENV === 'local' ||
      import.meta.env?.VITE_ENV === 'test' ||
      process.env?.VITE_ENV === 'local' ||
      process.env?.VITE_ENV === 'test';
    if (isPreProductionEnv) {
      return import.meta.env.VITE_AMOEBA_TEAM_ID as string;
    }
    if (auth?.isClient) {
      return auth?.clients?.[0]?.ms_team_id;
    }
    if (impersonatingAccountId) {
      const client = auth?.clients?.find(
        (c) => c.id === impersonatingAccountId
      );
      return client?.ms_team_id;
    }
    return '';
  }, [auth, impersonatingAccountId]);

  const props = {
    ...(companyName && { companyName }),
    ...(companyData && { companyData }),
  };

  useEffect(() => {
    setSrc(
      generateChatbotUrlWithKey(
        clientTeams as string,
        import.meta.env.VITE_AMOEBA_KEY as string
      )
    );
    setCss('hidden');
  }, [clientTeams]);

  return (
    <PageContainer name='files'>
      <DashboardWrapper {...props}>
        <div />
        <iframe
          title='Embedded chatbox'
          className={css}
          src={src}
          // eslint-disable-next-line react/no-unknown-property
          onLoadedData={() =>
            setSrc(generateChatbotUrlWithoutKey(clientTeams as string))
          }
          onLoad={() => setCss('w-full h-full bg-transparent border-none')}
        />
      </DashboardWrapper>
    </PageContainer>
  );
}
