import { useEffect } from 'react';
import { TableSort } from '@/types';
import { GenericTable } from '@/components/Table/Table';
import { FirmsDTO } from '@shared/types';
import {
  columns,
  FirmRows,
  mapApiFirmsToFirmRows,
} from '@/pages/UsersList/TableFirms.util';

interface TableFirmsProps {
  sortedFirms: FirmsDTO[];
  // eslint-disable-next-line react/no-unused-prop-types
  test?: boolean;
  sort: TableSort<FirmRows>;
  sortBy: (fieldName: keyof FirmRows) => void;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  recordsPerPage: number;
  setRecordsPerPage: (recordsPerPage: number) => void;
  searchText: string;
  onFirmRowClick?: (firmRow: FirmRows) => void;
}
function TableFirms({
  sortedFirms = [],
  sort,
  sortBy,
  selectedPage,
  setSelectedPage,
  recordsPerPage,
  setRecordsPerPage,
  searchText,
  onFirmRowClick,
}: TableFirmsProps) {
  useEffect(() => {
    setSelectedPage(1);
  }, [sort, setSelectedPage, recordsPerPage, searchText]);

  const tableData = mapApiFirmsToFirmRows(sortedFirms);

  return (
    <GenericTable
      className=''
      tableName='firms'
      data={tableData}
      columns={columns}
      sortBy={sortBy}
      sort={sort}
      recordsPerPage={recordsPerPage}
      selectedPage={selectedPage}
      onPageSelect={setSelectedPage}
      onSelectRowsChange={setRecordsPerPage}
      onRowClick={(row) => {
        if (onFirmRowClick) onFirmRowClick(row);
      }}
    />
  );
}

export default TableFirms;
export { TableFirms };
