export const validateDomain = (value: string) => {
  const domainPattern = /^(?!https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return (
    domainPattern.test(value) ||
    "Please enter a valid domain without the protocol (e.g., 'example.com')"
  );
};

export const validateEmail = (value: string) => {
  if (value !== value.toLowerCase()) {
    return 'Please use only lowercase letters in your email.';
  }

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(value) || 'Please enter a valid email.';
};
