import { fetcher } from '@/utils/queries';
import useSWR from 'swr';
import { FirmClients, FirmsDTO } from '../../../shared/types';

const useFirms = () => {
  const baseUrl = '/api/firms';

  const useGetFirms = () => {
    return useSWR<FirmsDTO[], Error>(`${baseUrl}/list`, fetcher, {
      revalidateOnFocus: false,
    });
  };

  const useGetFirmsClients = (clientId: number) => {
    return useSWR<FirmClients[], Error>(
      `${baseUrl}/clients/${clientId}`,
      fetcher
    );
  };

  const useGetFirmsWithUsers = () => {
    return useSWR<FirmClients[], Error>(`${baseUrl}/users`, fetcher);
  };

  return { useGetFirms, useGetFirmsClients, useGetFirmsWithUsers };
};

export default useFirms;
