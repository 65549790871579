import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder,
  faFile,
  faCircleChevronLeft,
  faEye,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FileItemExtended } from '@/components/UsersAccordionList/EditPermissionsModalSteps/interfaces';
import { hasSubItems } from '@/components/UsersAccordionList/EditPermissionsModalSteps/utils';
import { FileItem, File } from '@types';
import { useState } from 'react';
import './SelectFileOrFolderAccordion.css';
import { Button } from 'react-daisyui';
import { FilePreviewModal } from '@/components/FileBrowser/modals';
import { fetchUrl, processDownloadUrl } from '@/utils';
import { useRollbar } from '@rollbar/react';
import { alertErrorMessage } from '@/utils/alerts';
import useLogEvent from '@/utils/logEvent';
import { IUserContext, useUserContext } from '@/context/UserContext';

interface Props {
  clientId: number;
  file: FileItem;
  level: number;
  open: boolean;
  fatherIsOpen: boolean;
  showAccordionIcon: boolean;
  onSelectFile: (file: FileItem) => void;
}

export function SelectFileOrFolderAccordion({
  clientId,
  file,
  level,
  open,
  fatherIsOpen,
  showAccordionIcon,
  onSelectFile,
}: Props) {
  const { auth }: IUserContext = useUserContext(); // Use the context
  const rollbar = useRollbar();
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const [fileToPreview, setFileToPreview] = useState<boolean>(false);
  const childFatherIsOpen = fatherIsOpen && isOpen;
  const { logEvent } = useLogEvent();

  const downloadFile = () => {
    fetchUrl(file.id, file.teamId)
      .then(async (url) => {
        await processDownloadUrl(url, file.name);
        await logEvent(file, clientId, auth?.role as string);
      })
      .catch((err) => rollbar.log(err as Error));
  };

  return (
    <>
      {fileToPreview ? (
        <FilePreviewModal
          isOpen={fileToPreview}
          fileItem={file as File}
          onClose={() => {
            setFileToPreview(false);
          }}
          onDownload={() => downloadFile()}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onError={() => alertErrorMessage('File not found')}
        />
      ) : null}
      <br />
      <div
        className='flex items-start custom-accordion'
        key={`div-main-${file.id}-${level}`}
      >
        <input
          type='checkbox'
          id={`checkbox-accordion-${file.id}-${level}`}
          className='custom-accordion-checkbox'
          checked={isOpen}
          onChange={() => {}}
        />
        <div
          key={`div-folder-${file.id}-${level}`}
          className={`accordion-sibling ${
            level === 0 ? 'pl-0' : 'pl-6'
          } w-[95%]`}
        >
          <label
            className='flex items-center custom-accordion-label'
            htmlFor={`checkbox-accordion-${file.id}-${level}`}
            key={`label-folder-${file.id}-${level}`}
          >
            <FontAwesomeIcon
              icon={file.type === 'folder' ? faFolder : faFile}
              size='lg'
              className='px-2'
            />
            <h3
              key={`folder-name-${file.id}-${level}`}
              className='text-base ml-2 break-all max-w-xs'
            >
              {file.name}
            </h3>
            {file.type === 'file' ? (
              <div className='flex justify-end'>
                <Button
                  size='xs'
                  variant='outline'
                  key={`file-preview-${file.id}-${level}`}
                  className='menu-hover btn-accent group cursor-pointer ml-2'
                  onClick={(e) => {
                    e.stopPropagation();
                    setFileToPreview(true);
                  }}
                >
                  <FontAwesomeIcon
                    data-testid='grant-permissions-modal'
                    icon={faEye}
                    size='lg'
                    className='text-accent'
                  />
                </Button>
                <Button
                  size='xs'
                  variant='outline'
                  key={`file-select-${file.id}-${level}`}
                  className='menu-hover btn-accent group cursor-pointer ml-2'
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectFile(file);
                  }}
                >
                  <FontAwesomeIcon
                    data-testid='grant-permissions-modal'
                    icon={faCheck}
                    title='Select File'
                    size='lg'
                    className='text-accent'
                  />
                </Button>
              </div>
            ) : null}
          </label>
          <div className='custom-accordion-content'>
            {file.files?.map((subfolder) => (
              <SelectFileOrFolderAccordion
                clientId={clientId}
                file={subfolder}
                level={level + 1}
                open={level + 1 <= 1}
                fatherIsOpen={childFatherIsOpen}
                showAccordionIcon={hasSubItems(subfolder as FileItemExtended)}
                onSelectFile={onSelectFile}
                key={`select-file-component-${subfolder.id}`}
              />
            ))}
          </div>
        </div>
        <div
          className=' text-right flex absolute right-4 w-[5%]'
          key={`div-accordion-${file.id}-${level}`}
        >
          {showAccordionIcon ? (
            <FontAwesomeIcon
              icon={faCircleChevronLeft}
              onClick={() => setIsOpen(!isOpen)}
              className={`text-base cursor-pointer transform duration-100 transition ease-linear h-[24px] ${
                isOpen ? '-rotate-90' : ''
              } ${fatherIsOpen ? '' : 'hidden'}`}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
