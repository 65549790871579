import { DashboardCard } from '@/components/DashboardCard';
import formatBytes from '@/components/DragAndDrop/utils';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { get as getApi, postFiles, patch } from '@/utils/api';
import {
  Client,
  FileItem,
  InvestorReportCardDTO,
  InvestorReportDTO,
} from '@types';
import { useRef, useState } from 'react';
import { Button, Checkbox, Input } from 'react-daisyui';
import { isValidURLOrEmpty } from '@/utils/url';
import SelectFileComponent from '@/components/FileBrowser/modals/select-file/SelectFileComponent';
import {
  acceptedFileExtensions,
  MAX_SINGLE_FILE_SIZE,
} from '@/utils/constants';

import { GenericModal } from '@/components/Modal';
import { Spinner } from '@/components/Spinner';
import {
  getInvestorReportSignal,
  InvestorReportSignalData,
} from '@/pages/InvestorReporting/utils/investorReportDataSignal';
import { ReportStatuses } from '../../../../../../shared/constants';

const selectExistingFileOption = { index: 0, label: 'Select Existing File' };
const uploadFileOption = { index: 1, label: 'Upload File' };
const externalLinkOption = { index: 2, label: 'Use external link' };

const answerTypes = [
  selectExistingFileOption,
  uploadFileOption,
  externalLinkOption,
];

interface SimilarCardInterface {
  investors: string[];
  period: string;
  message: string;
}

export function InvestorReportCardNewStatus({
  investorReportsCard,
  client,
  setSpinner,
  onCardUpdated,
}: {
  investorReportsCard: InvestorReportCardDTO;
  client: Client;
  setSpinner: (value: boolean) => void;
  onCardUpdated: () => void;
}) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileItem | undefined>(
    undefined
  );
  const [externalLink, setExternalLink] = useState<string>('');
  const [sendNotification, setSendNotification] = useState<boolean>(false);
  const [answerType, setAnswerTypes] = useState<
    { index: number; label: string } | undefined
  >(undefined);
  const [similarCardInvestors, setSimilarCardInvestors] = useState<
    SimilarCardInterface | undefined
  >(undefined);

  const {
    reportData: investorReports,
    isReportsLoading: isLoading,
    mutateReports: mutate,
  } = getInvestorReportSignal() as InvestorReportSignalData;

  const openFileExplorer = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  const onChangeAnswerType = (type: string) => {
    const index = answerTypes.findIndex((item) => item.label === type);
    if (index > -1) {
      setAnswerTypes(answerTypes[index]);
    }
    if (type === uploadFileOption.label && files.length === 0) {
      openFileExplorer();
    }
  };

  const removeFile = (idx: number) => {
    const newArr = [...files];
    newArr.splice(idx, 1);
    setFiles(newArr);
  };

  const checkDisabled = () => {
    if (!answerType) return true;
    switch (answerType?.label) {
      case uploadFileOption.label:
        return !(files.length > 0);
      case selectExistingFileOption.label:
        return !selectedFile;
      case externalLinkOption.label:
        return !externalLink || !isValidURLOrEmpty(externalLink);
      default:
        return true;
    }
  };

  const handleSubmitFile = async (applyToOtherCards?: boolean) => {
    try {
      const formData = new FormData();
      formData.append('teamId', client.ms_team_id as string);
      if (applyToOtherCards) {
        formData.append('applyToOtherCards', 'true');
      }
      if (sendNotification) {
        formData.append('notifyInvestors', 'true');
      }
      files.forEach((file) => {
        formData.append('', file, encodeURIComponent(file.name));
      });
      await postFiles(
        `/investorReports/fileUpload/client/${client.id}/card/${
          investorReportsCard.id as number
        }`,
        formData,
        {
          timeout: 60000,
        }
      );
      alertMessageSuccess(
        'Files Successfully Uploaded! - OpStart team review in progress.'
      );
      await getApi('files/syncTempFiles');
      onCardUpdated();
      await mutate();
    } catch (_err) {
      alertErrorMessage('Error Uploading Files');
    }
  };

  const handleExternalLink = async (applyToOtherCards?: boolean) => {
    try {
      await patch(
        `investorReports/investorReportsCard/client/${client.id}/response/${
          investorReportsCard.id as number
        }`,
        {
          response: {
            externalUrl: externalLink,
          },
          applyToOtherCards,
          notifyInvestors: sendNotification,
        }
      );
      onCardUpdated();
      await mutate();
      alertMessageSuccess('Card updated successfully.');
    } catch {
      alertErrorMessage('Error saving external link, please try again later');
    }
  };

  const handleSelecteFile = async (applyToOtherCards?: boolean) => {
    try {
      await patch(
        `investorReports/investorReportsCard/client/${client.id}/response/${
          investorReportsCard.id as number
        }`,
        {
          response: {
            fileId: selectedFile?.id,
          },
          applyToOtherCards,
          notifyInvestors: sendNotification,
        }
      );
      onCardUpdated();
      await mutate();
      alertMessageSuccess('Card updated successfully.');
    } catch {
      alertErrorMessage('Error saving external link, please try again later');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (e.target.files && e.target.files.length > 0) {
        const newFiles: File[] = Array.from(e.target.files);
        setFiles((prevState: File[]) => [...prevState, ...newFiles]);
      }
    }
  };

  const onSave = async (applyToOtherCards?: boolean) => {
    setSimilarCardInvestors(undefined);
    setSpinner(true);
    switch (answerType?.label) {
      case uploadFileOption.label:
        if (files.length > 0) {
          await handleSubmitFile(applyToOtherCards);
        }
        break;
      case selectExistingFileOption.label:
        await handleSelecteFile(applyToOtherCards);
        break;
      case externalLinkOption.label:
        await handleExternalLink(applyToOtherCards);
        break;
      default:
        break;
    }
    setFiles([]);
    setExternalLink('');
    setSelectedFile(undefined);
    setSpinner(false);
  };

  const getSimilarCards = () => {
    const invalidStatuses = [
      ReportStatuses.closed,
      ReportStatuses.deleted,
      ReportStatuses.completed,
    ];
    const completedStatuses = [ReportStatuses.completed, ReportStatuses.closed];
    const report = investorReports?.find(
      (item) => item.id === investorReportsCard.investor_report_id
    );
    let reportsWithSimilarCards: InvestorReportDTO[] | undefined = [];
    if (investorReportsCard.isBeingEdited) {
      reportsWithSimilarCards = investorReports?.filter((item) => {
        if (
          !item.investor_report_cards ||
          item.period !== report?.period ||
          item.id === report?.id
        )
          return false;
        const cards = item.investor_report_cards?.filter(
          (card) =>
            card.title === investorReportsCard.title &&
            card.response?.externalUrl ===
              investorReportsCard.response?.externalUrl &&
            card.response?.type === investorReportsCard.response?.type &&
            card.response?.fileName ===
              investorReportsCard.response?.fileName &&
            completedStatuses.includes(card.status)
        );
        return cards.length > 0;
      });
    } else {
      reportsWithSimilarCards = investorReports?.filter((item) => {
        if (
          !item.investor_report_cards ||
          item.period !== report?.period ||
          item.id === report?.id
        )
          return false;
        const cards = item.investor_report_cards?.filter(
          (card) =>
            card.title === investorReportsCard.title &&
            !invalidStatuses.includes(card.status)
        );
        return cards.length > 0;
      });
    }

    return reportsWithSimilarCards;
  };

  const onConfirm = async () => {
    const reportsWithSimilarCards = getSimilarCards();
    if (!reportsWithSimilarCards || reportsWithSimilarCards.length === 0) {
      await onSave();
      return;
    }

    const { period } = reportsWithSimilarCards[0];

    const users = reportsWithSimilarCards
      .filter((rep) => rep.user_id)
      .map(
        (rep) =>
          `${rep.user?.first_name as string} ${rep.user?.last_name as string}`
      );

    const firms = reportsWithSimilarCards
      .filter((rep) => rep.firm_id)
      .map((rep) => rep.firm?.firm_name as string);

    let message = '';
    if (investorReportsCard.status !== ReportStatuses.completed) {
      message = `Do you want to apply this answer to all investors requesting the same data?`;
    } else {
      message = `Do you want to apply this answer to all investors who received the same response?`;
    }

    setSimilarCardInvestors({
      investors: [...users, ...firms],
      period,
      message,
    });
  };

  if (isLoading) {
    return <Spinner className='mx-auto w-16 mt-10' />;
  }

  return (
    <>
      {similarCardInvestors && similarCardInvestors.investors.length > 0 ? (
        <GenericModal
          isOpen={
            similarCardInvestors && similarCardInvestors.investors.length > 0
          }
          onClose={() => setSimilarCardInvestors(undefined)}
        >
          <div className='flex h-full flex-col'>
            <div className='flex items-center'>
              <div className='w-full'>
                <div className='font-bold text-base'>
                  {similarCardInvestors.message}
                </div>
                <br />
                {similarCardInvestors.investors.map((value) => (
                  <div key={`similar-card-firm-${value}`}>{value}</div>
                ))}
                <br />
                <div className='font-bold text-base'>Requested Data</div>
                <div>Period: {similarCardInvestors.period}</div>
                <div>Title: {investorReportsCard.title}</div>
                <br />
                <div className='flex justify-evenly'>
                  <Button
                    color='accent'
                    id='upload'
                    data-testid='download-preview-button'
                    size='sm'
                    variant='outline'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={() => onSave()}
                  >
                    Skip
                  </Button>
                  <Button
                    color='accent'
                    id='upload'
                    data-testid='download-preview-button'
                    size='sm'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={() => onSave(true)}
                  >
                    Apply to All
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </GenericModal>
      ) : null}
      <input
        data-testid='file-drag-input'
        placeholder='fileInput'
        className='hidden'
        ref={inputRef}
        type='file'
        onChange={handleChange}
        accept={acceptedFileExtensions}
      />
      <div className='pl-4 flex'>
        {answerTypes.map((answer) => {
          return (
            <span key={`${answer.index + 1}_frequency_span`}>
              <Button
                size='sm'
                onClick={() => onChangeAnswerType(answer.label)}
                className={`${
                  answer.label === answerType?.label
                    ? 'btn-active mr-6'
                    : 'mr-6'
                }`}
                key={`${answer.index + 1}_frequency_button`}
              >
                {answer.label}
              </Button>
            </span>
          );
        })}
      </div>
      <br />
      {answerType?.label === uploadFileOption.label ? (
        <div className='flex flex-col items-center p-3 w-[100%] px-4 pb-8'>
          {files.map((file: File, idx: number) => (
            <div
              key={`${file.name}_${file.size}`}
              className='flex flex-row space-x-5 w-[100%]'
            >
              <span className='w-3/6'>{file.name}</span>
              <span
                className={`w-1/6 ${
                  file.size > MAX_SINGLE_FILE_SIZE
                    ? 'text-red-600 text-bold'
                    : ''
                }`}
              >
                {formatBytes(file.size)}
              </span>
              <span
                className='text-red-500 cursor-pointer w-2/6'
                onClick={() => removeFile(idx)}
                onKeyDown={() => removeFile(idx)}
                role='button'
                tabIndex={-1}
              >
                remove
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {answerType?.label === selectExistingFileOption.label ? (
        <div className='px-4'>
          {!selectedFile ? (
            <DashboardCard className='p-4' cardBodyclassName='p-0'>
              <SelectFileComponent
                clientId={client.id}
                teamId={client.ms_team_id as string}
                onSelectFile={(file) => setSelectedFile(file)}
              />
            </DashboardCard>
          ) : null}

          {selectedFile ? (
            <div
              key={`${selectedFile.name}_${selectedFile.id}`}
              className='flex flex-row space-x-5 w-[100%]'
            >
              <span className='w-3/6'>{selectedFile.name}</span>
              <span
                className='text-red-500 cursor-pointer w-2/6'
                onClick={() => setSelectedFile(undefined)}
                onKeyDown={() => setSelectedFile(undefined)}
                role='button'
                tabIndex={-1}
              >
                remove
              </span>
            </div>
          ) : null}
          <br />
        </div>
      ) : null}
      {answerType?.label === externalLinkOption.label ? (
        <div className='px-4 pb-4'>
          <Input
            type='url'
            placeholder='Enter URL'
            className={`border-accent ${
              !isValidURLOrEmpty(externalLink) ? 'border-red-500' : ''
            }`}
            onChange={(e) => setExternalLink(e.target.value)}
          />
          {!isValidURLOrEmpty(externalLink) ? (
            <div className='text-red-500'>Invalid URL</div>
          ) : null}
        </div>
      ) : null}

      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={`px-4 pb-4 ${checkDisabled() ? 'opacity-20' : ''}`}
        onClick={() => setSendNotification(!sendNotification)}
      >
        <Checkbox
          checked={sendNotification}
          data-testid='showChurned'
          className='border-accent relative top-[7px]'
          onChange={() => setSendNotification(!sendNotification)}
        />
        <span className='text-white ml-2 btn-disabled'>
          Send a notification to the investor upon my answer submission
        </span>
      </div>

      <div className='px-4'>
        <Button
          type='submit'
          color='accent'
          size='sm'
          className='btn btn-accent'
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onConfirm}
          disabled={checkDisabled()}
        >
          Confirm
        </Button>
      </div>
    </>
  );
}
