/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useNavigate } from 'react-router-dom';
import { Notification } from '@types';
import { Button } from 'react-daisyui';

interface Props {
  notifications: Notification[];
  dismissNotification: (notificationId: number) => Promise<void>;
}

function Notifications({ notifications, dismissNotification }: Props) {
  const navigate = useNavigate();

  return (
    <div className='card-body'>
      {notifications.length > 0 ? (
        <ul className='list-none'>
          {notifications?.map((n: Notification) => (
            <li
              key={n.id}
              className='flex justify-between items-center p-2 border-b'
            >
              <span
                className='text-sm'
                onClick={() => {
                  navigate(`${n.url}`);
                  dismissNotification(Number(n?.id))
                    .then(() => {})
                    .catch(() => {
                      console.error('Error dismissing notification');
                    });
                }}
              >
                {n?.details?.title}
              </span>
              <Button
                size='sm'
                className='text-accent'
                onClick={() => {
                  dismissNotification(Number(n?.id))
                    .then(() => {})
                    .catch(() => {
                      console.error('Error dismissing notification');
                    });
                }}
                aria-label='dismiss-notification'
              >
                ✕
              </Button>
            </li>
          ))}
        </ul>
      ) : (
        <div className='card-body text-center text-gray-500'>
          No new notifications available
        </div>
      )}
    </div>
  );
}

export default Notifications;
