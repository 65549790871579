import { useLocation } from 'react-router-dom';
import {
  InvestorReportDTO,
  JWTUser,
  InvestorReportTemplateDTO,
  ExtendedInvestorReportCardDTO,
} from '@types';
import { useState, useMemo, useRef, useEffect } from 'react';
// eslint-disable-next-line max-len
import RequestListForInvestors from '@/pages/InvestorReporting/components/InvestorsViews/tables/RequestListForInvestors';
import { OpenCardsForClients } from '@/pages/InvestorReporting/components/ClientsViews/tables/OpenCardsForClients';
// eslint-disable-next-line max-len
import { RequestListForClients } from '@/pages/InvestorReporting/components/ClientsViews/tables/RequestListForClients';
import { PastUpdates } from '@/pages/InvestorReporting/components/PastUpdates';
// eslint-disable-next-line max-len
import { InvestorReportingBaseTableView } from '@/pages/InvestorReporting/components/tables/InvestorReportingBaseTableView';

interface Props {
  investorReports: InvestorReportDTO[];
  user?: JWTUser | null;
  allInvestorReports: InvestorReportDTO[];
  investorReportTemplates: InvestorReportTemplateDTO[];
  impersonatingAccountId: number | null;
  updateData: () => Promise<void>;
  currentView: 'activeRequests' | 'pastUpdates';
}

export function InvestorReportingManagement({
  investorReports,
  user,
  allInvestorReports,
  investorReportTemplates,
  impersonatingAccountId,
  updateData,
  currentView,
}: Props) {
  const pastUpdatesRef = useRef<HTMLDivElement>(null);
  const [cardsSearchText, setCardSearchText] = useState<string>('');
  const [openCards, setOpenCards] = useState<ExtendedInvestorReportCardDTO[]>(
    []
  );
  const [requestsSearchText, setRequestsSearchText] = useState<string>('');
  const [selectedCardsPage, setSelectedCardsPage] = useState<number>(1);
  const [selectedRequestsPage, setSelectedRequestsPage] = useState<number>(1);
  const [recordsPerPageCards, setRecordsPerPageCards] = useState<number>(10);
  const [recordsPerPageRequest, setRecordsPerPageRequest] =
    useState<number>(10);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const clientNameFromQuery = queryParams.get('clientName') || '';
  const showAllReports = queryParams.get('showAllReports');

  useEffect(() => {
    if (pastUpdatesRef.current && currentView === 'pastUpdates') {
      pastUpdatesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentView]);

  const filterReports = (reports: InvestorReportDTO[], searchKey: string) => {
    return reports.filter((report) => {
      const matchesClientName = clientNameFromQuery
        ? report.client?.client_name
            ?.toLowerCase()
            .includes(clientNameFromQuery.toLowerCase())
        : true;

      const matchesSearchText = report.client?.client_name
        ?.toLowerCase()
        .includes(searchKey.toLowerCase());

      return matchesClientName && matchesSearchText;
    });
  };

  const flattenOpenCards = (
    reports: InvestorReportDTO[]
  ): ExtendedInvestorReportCardDTO[] => {
    return reports.flatMap((report) => {
      return (report.investor_report_cards || [])
        .filter((card) => card.status === 'open')
        .map((card) => {
          return {
            ...card,
            firm: report.firm,
            user: report.user,
            parentReportId: report.id,
          };
        });
    });
  };

  useEffect(() => {
    setOpenCards(
      flattenOpenCards(
        showAllReports || currentView === 'pastUpdates'
          ? allInvestorReports
          : investorReports
      )
    );
  }, [allInvestorReports, investorReports, showAllReports, currentView]);

  const reportsForClients = useMemo(() => {
    const reports = showAllReports ? allInvestorReports : investorReports;
    return filterReports(reports, requestsSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    investorReports,
    allInvestorReports,
    showAllReports,
    requestsSearchText,
    clientNameFromQuery,
  ]);

  const canViewOpenCards =
    user?.isClient || (impersonatingAccountId && user?.isAdmin);

  return (
    <>
      <div className={`${canViewOpenCards ? 'mb-4' : ''}`}>
        {user?.isClient || (impersonatingAccountId && user?.isAdmin) ? (
          <InvestorReportingBaseTableView
            searchText={cardsSearchText}
            setSearchText={setCardSearchText}
            showAllReports={!!showAllReports}
            title='Open Requests'
            placeholder='Search Requests'
          >
            <OpenCardsForClients
              openCards={openCards}
              openRequests={reportsForClients}
              recordsPerPage={recordsPerPageCards}
              selectedPage={selectedCardsPage}
              onPageSelect={setSelectedCardsPage}
              onSelectRowsChange={setRecordsPerPageCards}
              searchText={cardsSearchText}
              refreshData={updateData}
            />
          </InvestorReportingBaseTableView>
        ) : null}
      </div>
      {currentView === 'pastUpdates' ? (
        <div ref={pastUpdatesRef}>
          <PastUpdates
            investorReports={allInvestorReports || []}
            investorReportTemplates={investorReportTemplates || []}
          />
        </div>
      ) : null}
      {currentView === 'activeRequests' ? (
        <InvestorReportingBaseTableView
          searchText={requestsSearchText}
          setSearchText={setRequestsSearchText}
          showAllReports={!!showAllReports}
          title={`${user?.isClient ? 'Connected Investors' : ''}`}
          placeholder='Search Reports'
        >
          {user?.isInvestor ? (
            <RequestListForInvestors
              investorReports={reportsForClients}
              recordsPerPage={recordsPerPageRequest}
              selectedPage={selectedRequestsPage}
              onPageSelect={setSelectedRequestsPage}
              onSelectRowsChange={setRecordsPerPageRequest}
              searchText={requestsSearchText}
              investorReportTemplates={investorReportTemplates}
              updateData={updateData}
            />
          ) : null}
          {user?.isClient || (impersonatingAccountId && user?.isAdmin) ? (
            <RequestListForClients
              investorReports={investorReports || []}
              recordsPerPage={recordsPerPageRequest}
              selectedPage={selectedRequestsPage}
              onPageSelect={setSelectedRequestsPage}
              onSelectRowsChange={setRecordsPerPageRequest}
              searchText={requestsSearchText}
              investorReportTemplates={investorReportTemplates}
            />
          ) : null}
        </InvestorReportingBaseTableView>
      ) : null}
    </>
  );
}
