import { GenericModal } from '@/components/Modal';
import { Client, FileItem, FirmsDTO } from '@types';
import { useState, useEffect } from 'react';
import useAccountData from '@/hooks/useAccountData';
import useFileData from '@/hooks/useFileData';
import {
  CustomTabItemExtended,
  FileItemExtended,
  SyftItemExtended,
} from '@/components/UsersAccordionList/EditPermissionsModalSteps/interfaces';
import {
  getCheckedFileIdRecursive,
  getOnlySelectedFolders,
  transformCustomTabItemToCustomTabItemExtended,
  transformFileItemtoFileItemExtended,
  transformSyftTabToSyftTabExtended,
} from '@/components/UsersAccordionList/EditPermissionsModalSteps/utils';
import GenericWizard from '@/components/GenericWizard/GenericWizard';
import FilesStep from '@/components/UsersAccordionList/EditPermissionsModalSteps/FilesStep';
import SyftDashboardStep from '@/components/UsersAccordionList/EditPermissionsModalSteps/SyftDashboardStep';
import CustomTabsStep from '@/components/UsersAccordionList/EditPermissionsModalSteps/CustomTabsStep';
import OverviewStep from '@/components/UsersAccordionList/EditPermissionsModalSteps/OverviewStep';
import SelectClientStep from '@/components/GenericWizard/AddClientSteps/SelectClientStep';
import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { patch } from '@/utils/api';
import { get } from '@/utils/queries';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { LoadingCard } from '@/components/DashboardCard';

export default function AddClientModal({
  firm,
  isOpen,
  onClose,
  mutateFirms,
}: {
  firm: FirmsDTO;
  isOpen: boolean;
  onClose: () => void;
  mutateFirms?: () => Promise<void>;
}) {
  const { auth }: IUserContext = useUserContext();
  const { getAccountData, refreshAccountData } = useAccountData();
  const { getFileData } = useFileData();
  const { data: rootFolder, isValidating: isLoading, error } = getFileData();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<Client | undefined>(
    undefined
  );
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const {
    data,
    error: accountError,
    isLoading: isLoadingAccountData,
  } = getAccountData(selectedClient?.id, true);

  const { data: clientData, isLoading: isloadingClients } =
    get<Client[]>('clients');

  const availableClients = (clientData || []).filter((client: Client) => {
    if (!firm.firm_clients) return true;
    return !firm.firm_clients.some((fc) => fc.client_id === client.id);
  });

  const [customTabsState, setCustomTabsState] = useState<
    CustomTabItemExtended[]
  >([]);

  const [syftUrlTabsState, setSyftUrlTabsState] = useState<SyftItemExtended[]>(
    []
  );

  const [filesState, setFilesState] = useState<FileItemExtended | null>(null);

  useEffect(() => {
    if (!isLoading && !isLoadingAccountData) {
      const clientFolders: FileItem | undefined = rootFolder
        ? rootFolder.files.find((e: FileItem) => e.id === data?.ms_team_id)
        : undefined;
      setFilesState(
        clientFolders
          ? transformFileItemtoFileItemExtended(
              clientFolders,
              undefined,
              undefined,
              firm.id
            )
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLoadingAccountData, firm.id, selectedClient?.id]);

  useEffect(() => {
    const syftUrl = (data?.config || []).find(
      (config) => config.config_key === 'syft_url'
    );
    setSyftUrlTabsState(
      transformSyftTabToSyftTabExtended(syftUrl, undefined, undefined, firm.id)
    );
  }, [data?.config, firm.id]);

  useEffect(() => {
    const customTabsConfig = (data?.config || []).find(
      (config) => config.config_key === 'custom_tab_options'
    );
    setCustomTabsState(
      transformCustomTabItemToCustomTabItemExtended(
        customTabsConfig,
        undefined,
        undefined,
        firm.id
      )
    );
  }, [data?.config, firm.id]);

  const updateCustomTabs = (customTabs: CustomTabItemExtended[]) => {
    setCustomTabsState(customTabs);
  };

  const updateSyftUrl = (syftTab: SyftItemExtended[]) => {
    setSyftUrlTabsState(syftTab);
  };

  const updateFiles = (files: FileItemExtended) => {
    setFilesState(files);
  };

  const getSelectedCustomTabs = () =>
    customTabsState.filter((tab) => tab.isChecked);

  const getSelectedSyftTab = () =>
    syftUrlTabsState.filter((tab) => tab.isChecked);

  const getSelectedFolders = () => getOnlySelectedFolders(filesState);

  const savePermissions = () => {
    setIsSaving(true);
    const selectedCustomTabsNames = getSelectedCustomTabs().map(
      (t) => t.titleOfTab
    );

    const selectedSyftUrl = getSelectedSyftTab().map((t) => t.syftUrl);

    const selectedFolderIds: string[] = [];
    if (filesState) {
      getCheckedFileIdRecursive(filesState, selectedFolderIds);
    }

    const firmData = [{ firmId: firm.id, skippedUsers: [] }];
    patch(`firms/addClientAndPermissions`, {
      firmId: firm.id,
      clientId: selectedClient?.id,
      filesPermissionsList: selectedFolderIds,
      syftPermissionUrls: selectedSyftUrl,
      customTabsPermissions: selectedCustomTabsNames,
      firmData,
    })
      .then(async () => {
        setIsSaving(false);
        await refreshAccountData(selectedClient?.id as number);
        alertMessageSuccess('Client added successfully!');
        await mutateFirms?.();
        onClose();
      })
      .catch(() => {
        alertErrorMessage('Something went wrong. Please try again.');
        setIsSaving(false);
      });
  };

  if (error || accountError) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={error as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  if (isloadingClients || isLoading) {
    return (
      <GenericModal isOpen={isOpen} onClose={onClose} className='max-w-[35%]'>
        <LoadingCard />
      </GenericModal>
    );
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} className='max-w-[35%]'>
      <GenericWizard disableStep={isSaving || isDisable}>
        <SelectClientStep
          clients={availableClients}
          title='Client'
          disableNext={setIsDisable}
          updateClient={setSelectedClient}
          clientIds={availableClients.map((c) => c.id)}
        />
        <FilesStep
          displayName={selectedClient?.client_name as string}
          folderList={filesState as FileItemExtended}
          updateFiles={updateFiles}
          title='Files'
          isLoading={isLoading}
        />
        <SyftDashboardStep
          syftDashboardOptions={syftUrlTabsState}
          updateSyftDashboardOptions={updateSyftUrl}
          title='Dashboard'
        />
        <CustomTabsStep
          customTabs={customTabsState}
          displayName={selectedClient?.client_name as string}
          updateCustomTabs={updateCustomTabs}
          title='Custom Tabs'
        />
        <OverviewStep
          customTabs={getSelectedCustomTabs()}
          folderList={getSelectedFolders().files}
          syftDashboardOption={getSelectedSyftTab()}
          isSaving={isSaving}
          title='Overview'
          onSave={savePermissions}
        />
      </GenericWizard>
    </GenericModal>
  );
}
