import { Client, InvestorReportCardDTO } from '@types';
import { Button, Textarea } from 'react-daisyui';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { patch } from '@/utils/api';
import { useState } from 'react';
import { ReportStatuses } from '../../../../../../shared/constants';

const initialMessage =
  'There are no updates to this request since the previous response.';

export function InvestorReportCloseCard({
  investorReportsCard,
  client,
  setSpinner,
  onCardUpdated,
  isFullWidth,
}: {
  investorReportsCard: InvestorReportCardDTO;
  client: Client;
  setSpinner: (value: boolean) => void;
  onCardUpdated: () => void;
  isFullWidth?: boolean;
}) {
  const [message, setMessage] = useState(initialMessage);

  const onConfirm = async () => {
    setSpinner(true);
    try {
      await patch(
        `investorReports/investorReportsCard/client/${client.id}/status/${
          investorReportsCard?.id as number
        }`,
        {
          status: ReportStatuses.closed,
          message,
        }
      );
      alertMessageSuccess('Card updated successfully');
      onCardUpdated();
    } catch {
      alertErrorMessage('Failed to update card, please try again later');
    }
    setSpinner(false);
  };

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMessage(event.target.value);
  };
  return (
    <div
      className={`${isFullWidth ? 'w-full' : 'w-[50%]'} ${
        isFullWidth
          ? ''
          : 'top-[60px] right-0 h-auto animate-in slide-in-from-right'
      }`}
      style={{ borderLeft: `${isFullWidth ? 'none' : '1px solid #d3d3d3'}` }}
    >
      <div className='p-4'>
        Title:
        <span className='ml-2 font-bold'>{investorReportsCard.title}</span>
      </div>
      <div className='px-4 pb-4'>
        Description:
        <span className='ml-2 font-bold'>
          {investorReportsCard.description}
        </span>
      </div>

      <div className='pl-4 flex'>Add a Note:</div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className='px-4 pb-4'>
        <Textarea
          id='message-founders'
          className='w-full input-accent p-2 rounded-md mt-4 text-left'
          rows={5}
          placeholder='Content'
          value={message}
          onChange={handleContentChange}
        />
      </div>

      <div className='px-4'>
        <Button
          type='submit'
          color='accent'
          size='sm'
          className='btn btn-accent'
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onConfirm}
          disabled={message.length < 3}
          data-testid={`confirm-close-card-${
            investorReportsCard?.id as number
          }`}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}
