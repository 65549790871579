/* eslint-disable react/destructuring-assignment */
import { InvestorReportDTO, InvestorReportTemplateDTO } from '@types';
import { Button } from 'react-daisyui';
import { Column } from '@/components/Table/Table';
import { DateTime } from 'luxon';
import { TableSort } from '@/types';
import {
  getStatusColor,
  InvestorReportingGroupedTableData,
  InvestorReportingTableData,
  ReportsGroupedData,
} from '@/pages/InvestorReporting/utils/investorReports';
import {
  ReportsFrequencies,
  ReportStatuses,
} from '../../../../../../../shared/constants';

export const columnsForClientsView: Column<InvestorReportingTableData>[] = [
  {
    header: 'investor name',
    accessor: 'investor_name',
    sortable: true,
    Cell: (row) => (
      <div role='button' tabIndex={0}>
        {row.investor_name}
      </div>
    ),
  },
  {
    header: 'Status',
    accessor: 'status',
    sortable: true,
    Cell: (row: InvestorReportingTableData) => (
      <div className={`w-4 h-4 rounded-full circle-${getStatusColor(row)}`} />
    ),
  },
  { header: 'Frequency', accessor: 'frequency', sortable: true },
  {
    header: 'Last Investor Update',
    accessor: 'last_investor_update',
    sortable: true,
  },
  {
    header: 'Date of Next Request',
    accessor: 'next_request_date',
    sortable: true,
  },
];

export const columnsForInvestorsPastUpdates: Column<InvestorReportingTableData>[] =
  [
    {
      header: 'Company Name',
      accessor: 'company_name',
      sortable: true,
      Cell: (row: InvestorReportingTableData) => (
        <div className='flex items-center'>
          <span>{row.company_name}</span>
        </div>
      ),
    },
    {
      header: 'Status',
      accessor: 'status',
      sortable: true,
      Cell: (row: InvestorReportingTableData) => (
        <div className={`w-4 h-4 rounded-full circle-${getStatusColor(row)}`} />
      ),
    },
    {
      header: 'Firm',
      accessor: 'firm_name',
      sortable: true,
    },
    {
      header: 'Date Submitted',
      accessor: 'last_investor_update',
      sortable: true,
    },
    {
      header: 'Reporting Period',
      accessor: 'period',
      sortable: true,
    },
    { header: 'Type', accessor: 'frequency', sortable: true },
  ];

export const columnsForClientsPastUpdates: Column<InvestorReportingTableData>[] =
  [
    {
      header: 'Investor Name',
      accessor: 'investor_name',
      sortable: true,
      Cell: (row: InvestorReportingTableData) => (
        <div className='flex items-center'>
          <span>{row.investor_name}</span>
        </div>
      ),
    },
    {
      header: 'Status',
      accessor: 'status',
      sortable: true,
      Cell: (row: InvestorReportingTableData) => (
        <div className={`w-4 h-4 rounded-full circle-${getStatusColor(row)}`} />
      ),
    },
    {
      header: 'Firm',
      accessor: 'firm_name',
      sortable: true,
    },
    {
      header: 'Date Submitted',
      accessor: 'last_investor_update',
      sortable: true,
    },
    {
      header: 'Reporting Period',
      accessor: 'period',
      sortable: true,
    },
    { header: 'Type', accessor: 'frequency', sortable: true },
  ];

export const columnsForInvestorView = (
  openNudgeModal: (row: InvestorReportingTableData) => void,
  includePeriodColumn: string | null
): Column<InvestorReportingTableData>[] => {
  const columns: Column<InvestorReportingTableData>[] = [
    {
      header: 'Company Name',
      accessor: 'company_name',
      sortable: true,
      Cell: (row: InvestorReportingTableData) => (
        <div className='flex items-center'>
          <span>{row.company_name}</span>
          {row.status === 'overdue' &&
            row.investor_report_nudge &&
            row.investor_report_nudge.length === 0 && (
              <Button
                color='accent'
                size='xs'
                className='ml-3 rounded-xl'
                onClick={() => openNudgeModal(row)}
              >
                Nudge
              </Button>
            )}
        </div>
      ),
    },
    {
      header: 'Firm',
      accessor: 'firm_name',
      sortable: true,
    },
    {
      header: 'Date of Next Request',
      accessor: 'next_request_date',
      sortable: true,
    },
    {
      header: 'Last Investor Update',
      accessor: 'last_investor_update',
      sortable: true,
    },
  ];

  if (includePeriodColumn) {
    columns.push({
      header: 'Period',
      accessor: 'period',
      sortable: true,
    });
  }

  return columns;
};

const resolveNextRequestDate = (
  report: InvestorReportDTO,
  template: InvestorReportTemplateDTO | undefined
) => {
  const now = DateTime.now();
  const date = DateTime.fromISO(report.created_at as unknown as string);
  switch (report.frequency) {
    case ReportsFrequencies.monthly: {
      if (!template) return ' - ';
      const currentStartingDate = DateTime.local(
        now.year,
        now.month,
        template?.starting_date || 1
      );
      const createdAt = DateTime.fromISO(
        report.created_at as unknown as string
      );
      let nexRequestDate = '';
      if (currentStartingDate > createdAt) {
        nexRequestDate = currentStartingDate.toFormat('MM/dd/yyyy');
      } else {
        nexRequestDate = currentStartingDate
          .plus({ month: 1 })
          .toFormat('MM/dd/yyyy');
      }
      return nexRequestDate;
    }

    case ReportsFrequencies.quarterly: {
      const currentQuarter = Math.ceil(date.month / 3);

      const currentQuarterStart = DateTime.fromObject({
        year: date.year,
        month: (currentQuarter - 1) * 3 + 1,
        day: 1,
      }).startOf('day');

      const nextQuarterStart = currentQuarterStart.plus({ months: 3 });
      return nextQuarterStart.toFormat('MM/dd/yyyy');
    }

    case ReportsFrequencies.annual: {
      const currentYearStart = DateTime.fromObject({
        year: date.year,
        month: 1,
        day: 1,
      }).startOf('day');

      const nextYearStart = currentYearStart.plus({ years: 1 });
      return nextYearStart.toFormat('MM/dd/yyyy');
    }

    case ReportsFrequencies.oneTime:
      return ' - ';

    default:
      return ' - ';
  }
};

export const mapApiInvestorReportsGroupedToTableData = (
  values: ReportsGroupedData[]
): InvestorReportingGroupedTableData[] => {
  return values.map((value) => {
    const lastInvestorUpdate = value.investorReports
      .map((report) => DateTime.fromISO(report.created_at?.toString() || ''))
      .filter((date) => date !== null)
      .sort((a, b) => b.toMillis() - a.toMillis());

    const nextRequestData = value.investorReportTemplates
      .map((template) => {
        const report = value.investorReports.find(
          (r) =>
            r.frequency === template.frequency &&
            r.client_id === template.client_id &&
            r.firm_id === template.firm_id &&
            r.user_id === template.user_id
        );
        const nextDate = resolveNextRequestDate(
          report ||
            ({
              created_at: DateTime.now().toISO().toString(),
              frequency: template.frequency,
            } as InvestorReportDTO),
          template
        );
        return DateTime.fromFormat(nextDate, 'MM/dd/yyyy');
      })
      .filter((date) => date !== null && date.isValid)
      .sort((a, b) => a.toMillis() - b.toMillis());

    return {
      ...value,
      last_investor_update:
        lastInvestorUpdate[0]?.toFormat('MM/dd/yyyy') || 'N/A ',
      next_request_date: nextRequestData[0]?.toFormat('MM/dd/yyyy') || '',
      overdueReports: value.investorReports.filter(
        (report) =>
          report.status === ReportStatuses.overdue &&
          (!report.investor_report_nudge ||
            report.investor_report_nudge.length === 0)
      ),
    } as InvestorReportingGroupedTableData;
  });
};

export const columnsForInvestorGroupedView = (
  openNudgeModal: (
    rows: InvestorReportDTO[],
    e: React.FormEvent<HTMLButtonElement>
  ) => void
): Column<InvestorReportingGroupedTableData>[] => {
  const columns: Column<InvestorReportingGroupedTableData>[] = [
    {
      header: 'Company Name',
      accessor: 'company_name',
      sortable: true,
      Cell: (row: InvestorReportingGroupedTableData) => {
        const hasOverdueReports = row.overdueReports.length > 0;
        const overdueTooltip = row.overdueReports
          .map((report) => report.period)
          .join(' - ');
        return (
          <div
            className={`flex items-center ${
              row.investorReports.length === 0
                ? 'pointer-events-none'
                : 'cursor-pointer'
            }`}
          >
            <span>{row.company_name}</span>
            {hasOverdueReports ? (
              <Button
                color='accent'
                size='xs'
                className='ml-3 rounded-xl tooltip tooltip-accent'
                data-tip={overdueTooltip}
                onClick={(e) => openNudgeModal(row.overdueReports, e)}
              >
                Nudge
              </Button>
            ) : null}
            {row.investorReports.length === 0 ? (
              <Button
                color='warning'
                size='xs'
                className='ml-3 rounded-xl pointer-events-none'
              >
                Pending
              </Button>
            ) : null}
          </div>
        );
      },
    },
    {
      header: 'Investor',
      accessor: 'investor_name',
      sortable: true,
    },
    {
      header: 'Date of Next Request',
      accessor: 'next_request_date',
      sortable: true,
    },
    {
      header: 'Last Investor Update',
      accessor: 'last_investor_update',
      sortable: true,
    },
  ];

  return columns;
};

const getSortValueForInvestorGroupedView = (
  sortField: string,
  report: InvestorReportingGroupedTableData
) => {
  switch (sortField) {
    case 'name':
      return report?.investor_name;
    case 'last_investor_update':
      return report?.last_investor_update
        ? new Date(report?.last_investor_update).getTime()
        : 0;
    case 'next_request_date':
      return report?.next_request_date
        ? new Date(report?.next_request_date).getTime()
        : 0;
    default:
      return report[sortField as keyof InvestorReportingGroupedTableData];
  }
};

export const sortForInvestorGroupedView = (
  sort: TableSort<InvestorReportingGroupedTableData>
) => {
  return (
    a: InvestorReportingGroupedTableData,
    b: InvestorReportingGroupedTableData
  ) => {
    const obj1 = getSortValueForInvestorGroupedView(sort.field, a);
    const obj2 = getSortValueForInvestorGroupedView(sort.field, b);
    if (obj1?.toString() && obj2?.toString() && obj1 > obj2)
      return sort.asc ? 1 : -1;
    return sort.asc ? -1 : 1;
  };
};
