import React, { useMemo } from 'react';
import { Table } from 'react-daisyui';
import { SortArrow } from '@/components/SortArrow/SortArrow';
import { OSPagination } from '@/components/Pagination/Pagination';
import { TableSort } from '@/types';

interface BackendPaginationData {
  totalRecords: number;
}

interface TableProps<T> {
  data: T[];
  className?: string;
  tableName: string;
  columns: Column<T>[];
  sortBy: (field: keyof T) => void;
  sort: TableSort<T>;
  recordsPerPage: number;
  selectedPage: number;
  onPageSelect: (page: number) => void;
  onSelectRowsChange: (rowsPerPage: number) => void;
  onRowClick?: (row: T) => void;
  sortFunction?: (a: T, b: T) => number;
  backendPaginationData?: BackendPaginationData;
}

export interface Column<T> {
  header: string;
  accessor: keyof T;
  Cell?: (row: T) => React.ReactNode;
  sortable?: boolean;
}

export function GenericTable<T>({
  className,
  tableName,
  data,
  columns,
  sortBy,
  sort,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
  onRowClick,
  sortFunction,
  backendPaginationData,
}: TableProps<T>) {
  const sortedData = useMemo(() => {
    if (sortFunction) return [...data].sort(sortFunction);

    return [...data].sort((a, b) => {
      const aValue = a[sort.field];
      const bValue = b[sort.field];

      if (aValue > bValue) return sort.asc ? 1 : -1;
      if (aValue < bValue) return sort.asc ? -1 : 1;
      return 0;
    });
  }, [data, sort, sortFunction]);

  const paginatedData = useMemo(() => {
    if (backendPaginationData?.totalRecords) return sortedData;
    const start = (selectedPage - 1) * recordsPerPage;
    const end = start + recordsPerPage;
    return sortedData.slice(start, end);
  }, [
    sortedData,
    selectedPage,
    recordsPerPage,
    backendPaginationData?.totalRecords,
  ]);

  const getPageNumbers = () => {
    let result = 0;
    if (backendPaginationData) {
      result = Math.ceil(backendPaginationData.totalRecords / recordsPerPage);
    } else {
      result = Math.ceil(data.length / recordsPerPage);
    }

    return result;
  };

  return (
    <>
      <Table zebra id={`Table_${tableName}`} className={className}>
        <Table.Head data-testid={`Table_${tableName}`}>
          {columns.map((column) => (
            <div
              key={String(column.accessor)}
              role='button'
              tabIndex={0}
              className={`cursor-pointer ${
                column.sortable ? '' : 'pointer-events-none'
              }`}
              onClick={() => column.sortable && sortBy(column.accessor)}
            >
              <span className='text-base font-bold capitalize'>
                {column.header}
                {column.sortable ? (
                  <SortArrow sort={sort} fieldName={column.accessor} />
                ) : null}
              </span>
            </div>
          ))}
        </Table.Head>
        <Table.Body className='text-sm'>
          {paginatedData.map((row, index) => (
            <Table.Row
              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              key={(row as any).id || `row_${tableName}_${index}`}
              data-testid={`Table_${tableName}_row_item`}
              hover
              onClick={() => onRowClick && onRowClick(row)}
            >
              {columns.map((column) => (
                <div key={String(column.accessor)}>
                  {column.Cell
                    ? column.Cell(row)
                    : (row[column.accessor] as unknown as React.ReactNode)}
                </div>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {data.length === 0 && <div className='text-center'>No Records Found</div>}
      <div className='mb-4 text-right' data-testid='pagination'>
        <OSPagination
          numPages={getPageNumbers()}
          selectedPage={selectedPage}
          rowsPerPage={recordsPerPage}
          selectedRowsPerPage={[5, 10, 20, 30, 40, 50]}
          onPageSelect={onPageSelect}
          onSelectRowsChange={onSelectRowsChange}
        />
      </div>
    </>
  );
}
