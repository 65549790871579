export enum ReportStatuses {
  open = 'open',
  partial = 'partial',
  completed = 'completed',
  overdue = 'overdue',
  closed = 'closed',
  deleted = 'deleted',
}

export enum ReportsFrequencies {
  monthly = 'Monthly',
  quarterly = 'Quarterly',
  annual = 'Annual',
  oneTime = 'One-Time',
}

export enum ResponseTypes {
  newFile = 'newFile',
  existingFile = 'Existing File',
  externalFile = 'External File',
  markAsClosed = 'Mark as Closed',
}
