/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { FirmsDTO } from '@shared/types';
import { openHubspotClient } from '@/utils/hubspot';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { Accordion } from '@/components/Accordion/Accordion';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { useRollbar } from '@rollbar/react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchDelete } from '@/utils/api';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import useAccountData from '@/hooks/useAccountData';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';

export default function FirmClients({
  firm,
  mutateFirms,
}: {
  firm: FirmsDTO;
  mutateFirms: () => Promise<void>;
}) {
  const { auth }: IUserContext = useUserContext();
  const { refreshAccountData } = useAccountData();
  const rollbar = useRollbar();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [modalState, setModalState] = useState({
    showRemoveModal: false,
    showEmailModal: false,
    showModalEditAccess: false,
  });
  const [modalClientId, setModalClientId] = useState<number | undefined>(
    undefined
  );
  const showDeleteButton = auth?.isAdmin || (auth?.isClient && auth.primary);

  const removeFirmFromClient = async (clientId: number) => {
    if (clientId) {
      setIsSaving(true);
      try {
        await fetchDelete(`firms/firmClient/${firm.id}/${clientId}`);
        await refreshAccountData(clientId);
        await mutateFirms();
        alertMessageSuccess('Firm removed successfully');
      } catch (err) {
        rollbar.error(
          `firmsAccordionList.tsx - removeFirmFromClient - FirmId: ${firm.id} - ClientId: ${clientId}`,
          err as Error
        );
        alertErrorMessage('Failed to remove firm from client');
      } finally {
        setIsSaving(false);
        setModalState({ ...modalState, showRemoveModal: false });
      }
    }
  };

  return (
    <>
      {showDeleteButton && modalClientId && modalState.showRemoveModal ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={() => removeFirmFromClient(modalClientId)}
          onCloseAction={() =>
            setModalState({ ...modalState, showRemoveModal: false })
          }
          showModal={modalState.showRemoveModal}
          modalMessage={`Are you sure you want to remove client from firm:  
            ${firm.firm_name}?`}
          isLoading={isSaving}
        />
      ) : null}
      {firm.firm_clients?.map((firmClient, index) => (
        <Accordion
          key={`${firmClient.client_id}_${firmClient.firm?.firm_name}`}
          showBottomSeparator
          open={index === 0}
          toggler={
            <div className='flex'>
              <span className='font-bold capitalize'>
                {`${firmClient.client?.client_name}`}
              </span>
            </div>
          }
        >
          <div className='flex'>
            <div className='flex-grow'>
              <div className='mt-2 flex justify-between'>
                <div>
                  <span className='text-gray-400 mr-2'>Email:</span>
                  <span>{firmClient.client?.hubspot_data?.group_email}</span>
                </div>
                <div className='flex'>
                  <Button
                    color='warning'
                    data-testid={`hubspot-button-${firmClient?.client?.hubspot_id}`}
                    variant='outline'
                    size='sm'
                    onClick={() => {
                      openHubspotClient(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        firmClient.client?.hubspot_id || undefined
                      );
                    }}
                    startIcon={<FontAwesomeIcon icon={faHubspot as IconProp} />}
                  />{' '}
                  {showDeleteButton ? (
                    <div className='ml-2'>
                      <Button
                        color='error'
                        data-testid={`remove-firm-client-${firmClient.client?.hubspot_id}`}
                        variant='outline'
                        size='sm'
                        onClick={() => {
                          setModalClientId(firmClient.client_id);
                          setModalState({
                            ...modalState,
                            showRemoveModal: true,
                          });
                        }}
                        startIcon={
                          <FontAwesomeIcon icon={faTrash as IconProp} />
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      ))}
    </>
  );
}
