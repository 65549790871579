import {
  faChartSimple,
  faSquarePollVertical,
  faSquarePollHorizontal,
  faChartPie,
  faChartLine,
  faChartColumn,
  faChartBar,
  faPiggyBank,
  faMoneyCheckDollar,
  faMoneyBillTrendUp,
  faMoneyBillTransfer,
  faRankingStar,
  faCreditCard,
  faCoins,
  faSackDollar,
  faHandHoldingDollar,
  faUser,
  faFileSignature,
  faAddressCard,
  faToolbox,
  faPeopleGroup,
  faFileExcel,
  faTable,
  IconDefinition,
  faBriefcase,
  faUsers,
  faCloud,
  faHome,
  faTasks,
  faComments,
} from '@fortawesome/free-solid-svg-icons';

export const iconList: IconDefinition[] = [
  faChartSimple,
  faSquarePollVertical,
  faSquarePollHorizontal,
  faChartPie,
  faChartLine,
  faChartColumn,
  faChartBar,
  faPiggyBank,
  faMoneyCheckDollar,
  faMoneyBillTrendUp,
  faMoneyBillTransfer,
  faRankingStar,
  faCreditCard,
  faCoins,
  faSackDollar,
  faHandHoldingDollar,
  faUser,
  faFileSignature,
  faAddressCard,
  faToolbox,
  faPeopleGroup,
  faFileExcel,
  faTable,
];

const isPreProductionEnv =
  import.meta.env?.VITE_ENV === 'local' ||
  import.meta.env?.VITE_ENV === 'test' ||
  process.env?.VITE_ENV === 'local' ||
  process.env?.VITE_ENV === 'test';

const clientOptions = [
  { icon: faHome, label: 'Home', url: '/home' },
  { icon: faUser, label: 'Account', url: '/account' },
  { icon: faCloud, label: 'Files', url: '/files' },
  { icon: faChartLine, label: 'Investor Reporting', url: '/i-reporting' },
];

const investorOptions = [
  { icon: faHome, label: 'Home', url: '/i-home' },
  { icon: faCloud, label: 'All Files', url: '/i-files' },
  { icon: faUser, label: 'Account', url: '/i-account' },
  { icon: faChartLine, label: 'Investor Reporting', url: '/i-reporting' },
];

export const getIconDefinitionByName = (iconName: string) =>
  iconList.find(
    (icon) => icon.iconName.toLowerCase() === iconName.toLowerCase()
  );

export const MENU_BY_USER = {
  superadmin: [
    { icon: faBriefcase, label: 'Clients', url: '/clients' },
    { icon: faUsers, label: 'Users', url: '/users' },
    { icon: faCloud, label: 'OneDrive', url: '/files' },
  ],
  specialadmin: [
    { icon: faBriefcase, label: 'Clients', url: '/clients' },
    { icon: faCloud, label: 'OneDrive', url: '/files' },
  ],
  admin: [
    { icon: faBriefcase, label: 'Clients', url: '/clients' },
    { icon: faCloud, label: 'OneDrive', url: '/files' },
  ],
  client: clientOptions,
  clientInvestor: [
    { icon: faHome, label: 'Home', url: '/home' },
    { icon: faCloud, label: 'Files', url: '/files' },
  ],
  investor: investorOptions,
};

export const BOTTOM_MENU_BY_USER = {
  superadmin: [],
  specialadmin: [],
  admin: [],
  client: isPreProductionEnv
    ? [{ icon: faComments, label: 'Chatbot', url: '/chatbot' }]
    : [],
  clientInvestor: isPreProductionEnv
    ? [{ icon: faComments, label: 'Chatbot', url: '/chatbot' }]
    : [],
  investor: [],
};

export const MENU_ADMIN_EXCLUSIVES = [
  {
    icon: faTasks,
    label: 'Categorizations',
    url: '/categorizations',
  },
];
