import React, { useState } from 'react';
import { Button } from 'react-daisyui';
import {
  FirmsDTO,
  FirmsWithUserDTO,
  FirmUsersWithUserDTO,
} from '@shared/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer } from 'react-toastify';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { Accordion } from '@/components/Accordion/Accordion';
import AddInvestorModal from '@/components/InvestorAddUserCard/AddInvestorModal';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faStar } from '@fortawesome/free-solid-svg-icons';
import { fetchDelete, patch } from '@/utils/api';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { useRollbar } from '@rollbar/react';

interface Props {
  firm: FirmsWithUserDTO;
  size?: 'sm' | 'md';
  mutateFirms: () => Promise<void>;
}

function FirmUsers({ firm, size = 'md', mutateFirms }: Props) {
  const rollbar = useRollbar();
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const [showModalRemoveFirm, setShowModalRemoveFirm] = useState(false);
  const [showModalPrimary, setShowModalPrimary] = useState(false);
  const [modalUser, setModalUser] = useState<FirmUsersWithUserDTO | null>(null);

  const onShowModalRemoveFirmUser = (
    user: FirmUsersWithUserDTO,
    show = true
  ) => {
    setShowModalRemoveFirm(show);
    if (show) {
      setModalUser(user);
    }
  };

  const onShowModalPrimary = (user: FirmUsersWithUserDTO, show = true) => {
    setShowModalPrimary(show);
    if (show) {
      setModalUser(user);
    }
  };

  const removeFirmUser = async (firmUser: FirmUsersWithUserDTO) => {
    try {
      await fetchDelete('firms/firmUser', {
        firmId: firmUser.firm_id,
        userId: firmUser.user_id,
      });
      await mutateFirms();
      alertMessageSuccess('FirmUser removed Successfully!');
    } catch (error) {
      rollbar.error(
        `RenderUser.tsx - removeFirmUser: Error removing firm user ${
          firm.firm_users?.find((fu) => fu.user.id === firmUser.user_id)?.user
            .email_address as string
        }`,
        error as Error
      );

      alertErrorMessage('Operation failed.');
    } finally {
      setShowModalRemoveFirm(false);
    }
  };

  const updatePrimaryUser = async () => {
    const primaryValue = !modalUser?.primary || false;

    try {
      await patch(
        `firms/updatePrimaryUser/${Number(firm.id)}/${Number(
          modalUser?.user_id
        )}`,
        {
          primary: primaryValue,
        }
      );
      const firmHasOtherPrimary =
        primaryValue ||
        firm.firm_users?.some(
          (user) => user.primary && user.user_id !== modalUser?.user_id
        );
      if (!firmHasOtherPrimary) {
        alertMessageSuccess(
          `User updated. No primary user exist for ${firm.firm_name}`
        );
      } else {
        alertMessageSuccess('User updated!');
      }
      await mutateFirms();
    } catch (error) {
      rollbar.error(
        `RenderUser.tsx - UpdatePrimary: Error updating primary user ${
          firm.firm_users?.find((fu) => fu.user.id === modalUser?.user_id)?.user
            .email_address as string
        }`,
        error as Error
      );

      alertErrorMessage('Operation failed.');
    } finally {
      setShowModalPrimary(false);
    }
  };

  const fontSize = size === 'md' ? 'text-base' : 'text-sm';
  return (
    <>
      <ToastContainer />
      {showModalRemoveFirm ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={() =>
            removeFirmUser(modalUser as FirmUsersWithUserDTO)
          }
          onCloseAction={() => setShowModalRemoveFirm(false)}
          showModal={showModalRemoveFirm}
          modalMessage={`Are you sure you want to delete this user 
            ${modalUser?.user.email_address as string}?`}
        />
      ) : null}
      {showModalPrimary ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={async () => {
            await updatePrimaryUser();
          }}
          onCloseAction={() => setShowModalPrimary(false)}
          showModal={showModalPrimary}
          modalMessage={`Are you sure you want to change the user
            ${modalUser?.user.email_address as string} as a ${
            modalUser?.primary ? 'Regular User' : 'Primary User'
          }?`}
        />
      ) : null}
      <div className='mb-2'>
        <Button
          type='submit'
          title='login'
          color='accent'
          size='sm'
          className='btn btn-accent bg-transparentp-2 m-2 ml-0'
          data-testid='add-user-button'
          onClick={() => setShowModalAddUser((prev) => !prev)}
        >
          Add User
        </Button>
        {showModalAddUser ? (
          <AddInvestorModal
            firm={firm as FirmsDTO}
            isOpen={showModalAddUser}
            onClose={() => setShowModalAddUser(false)}
            mutateFirms={mutateFirms}
          />
        ) : null}
      </div>
      {!firm.firm_users || firm.firm_users.length === 0 ? (
        <div className='mt-2 text-gray-400'>No users yet</div>
      ) : (
        firm.firm_users?.map(
          (firmUser: FirmUsersWithUserDTO, index: number) => (
            <Accordion
              key={firmUser.user.id}
              showBottomSeparator
              open={index === 0}
              toggler={
                <div className='flex'>
                  <span className={`${fontSize} font-bold capitalize`}>
                    {`${firmUser.user?.first_name} ${firmUser.user?.last_name}`}
                  </span>
                  {firmUser.primary ? (
                    <div className='flex items-start ml-2'>
                      <div className='badge badge-primary font-bold'>
                        Primary
                      </div>
                    </div>
                  ) : null}
                </div>
              }
            >
              <div className='flex'>
                <div className='flex-grow'>
                  <div className='mt-2'>
                    <span className={`${fontSize} text-gray-400 mr-2`}>
                      Contact Email:
                    </span>
                    <span className={`${fontSize} font-bold`}>
                      {firmUser.user.email_address}
                    </span>
                  </div>
                  <div className='mt-2'>
                    <span className={`${fontSize} text-gray-400 mr-2`}>
                      Job Title:
                    </span>
                    <span className={`${fontSize} font-bold`}>
                      {firmUser.user.job_title}
                    </span>
                  </div>
                </div>
                <div className='flex items-center mt-16 ml-2'>
                  <Button
                    color='error'
                    data-testid='disable-button'
                    variant='outline'
                    size='sm'
                    className='ml-1'
                    onClick={() => {
                      onShowModalRemoveFirmUser(firmUser);
                    }}
                    startIcon={<FontAwesomeIcon icon={faTrash as IconProp} />}
                  />
                </div>
                <div className='flex items-center mt-16 ml-2'>
                  <Button
                    color={firmUser.primary ? 'error' : 'primary'}
                    data-testid='change-primary'
                    variant='outline'
                    size='sm'
                    className='ml-1'
                    onClick={() => {
                      onShowModalPrimary(firmUser);
                    }}
                    title={
                      firmUser.primary ? 'Set as Regular' : 'Set as Primary'
                    }
                    startIcon={<FontAwesomeIcon icon={faStar as IconProp} />}
                  />
                </div>
              </div>
            </Accordion>
          )
        )
      )}
    </>
  );
}

export default FirmUsers;
