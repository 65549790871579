import { Button } from 'react-daisyui';
import { frequencies } from '@/pages/InvestorReporting/utils/investorReports';
import { ReportsFrequencies } from '../../../../../shared/constants';

export default function FrequencySelector({
  selectedFrequency,
  enabledFrequencies,
  onChangeFrequency,
}: {
  selectedFrequency: ReportsFrequencies;
  enabledFrequencies: ReportsFrequencies[];
  onChangeFrequency: (frequency: ReportsFrequencies) => void;
}) {
  return (
    <>
      {frequencies.map((data) => {
        return (
          <span key={`${data.index + 1}_frequency_span`}>
            <Button
              size='sm'
              onClick={() => onChangeFrequency(data.label)}
              className={`${
                data.label === selectedFrequency ? 'btn-active' : ''
              }`}
              key={`${data.index + 1}_frequency_button`}
              disabled={!enabledFrequencies.includes(data.label)}
            >
              {data.label}
            </Button>
          </span>
        );
      })}
    </>
  );
}
