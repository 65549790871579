import { Client, InvestorReportCardDTO, File } from '@types';
// eslint-disable-next-line max-len
import { InvestorReportCardNewStatus } from '@/pages/InvestorReporting/components/InvestorReportCards/InvestorReportCardNewStatus';
// eslint-disable-next-line max-len
import { InvestorReportCardCompleted } from '@/pages/InvestorReporting/components/InvestorReportCards/InvestorReportCardCompleted';
import { ReportStatuses } from '../../../../../../shared/constants';

export function InvestorReportAnswer({
  investorReportsCard,
  client,
  setSpinner,
  onCardUpdated,
  isFullWidth,
  setFileToPreview,
}: {
  investorReportsCard: InvestorReportCardDTO;
  client: Client;
  setSpinner: (value: boolean) => void;
  onCardUpdated: () => void;
  isFullWidth?: boolean;
  setFileToPreview: (value: File) => void;
}) {
  return (
    <div
      className={`${isFullWidth ? 'w-full' : 'w-[50%]'} ${
        isFullWidth
          ? ''
          : 'top-[60px] right-0 h-auto animate-in slide-in-from-right'
      }`}
      style={{ borderLeft: `${isFullWidth ? 'none' : '1px solid #d3d3d3'}` }}
    >
      <div className='p-4'>
        Title:
        <span className='ml-2 font-bold'>{investorReportsCard.title}</span>
      </div>
      <div className='px-4 pb-4'>
        Description:
        <span className='ml-2 font-bold'>
          {investorReportsCard.description}
        </span>
      </div>
      {investorReportsCard.status === ReportStatuses.open ||
      investorReportsCard.isBeingEdited ? (
        <InvestorReportCardNewStatus
          investorReportsCard={investorReportsCard}
          client={client}
          setSpinner={setSpinner}
          onCardUpdated={onCardUpdated}
        />
      ) : null}
      {(investorReportsCard.status === ReportStatuses.completed ||
        investorReportsCard.status === ReportStatuses.closed) &&
      !investorReportsCard.isBeingEdited ? (
        <InvestorReportCardCompleted
          investorReportsCard={investorReportsCard}
          setFileToPreview={setFileToPreview}
        />
      ) : null}
    </div>
  );
}
