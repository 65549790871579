import { useMemo, useState } from 'react';
import { TableSort } from '@/types';
import { getNewSortStateTable } from '@/utils/tableSort';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line max-len
import {
  InvestorListProps,
  InvestorReportingGroupedTableData,
  generateReportsTableData,
  generateURLForReportDetails,
} from '@/pages/InvestorReporting/utils/investorReports';
import {
  columnsForClientsViewV2,
  mapApiInvestorReportsGroupedToTableData,
  sortForInvestorReportingTableGroupedData,
} from '@/pages/InvestorReporting/components/ClientsViews/tables/investorReportingClient.util';
import { GenericTable } from '@/components/Table/Table';

export function RequestListForClients({
  investorReports,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
  searchText,
  investorReportTemplates,
}: InvestorListProps) {
  const navigate = useNavigate();
  const [sort, setSort] = useState<
    TableSort<InvestorReportingGroupedTableData>
  >({
    field: 'action' as keyof InvestorReportingGroupedTableData,
    asc: true,
  });

  const sortBy = (fieldName: keyof InvestorReportingGroupedTableData) => {
    const newSort = getNewSortStateTable(fieldName, sort);
    setSort(newSort);
  };

  const sortedReports = useMemo(() => {
    const reportsData = generateReportsTableData(
      investorReports,
      investorReportTemplates
    );

    return reportsData.filter((report) =>
      report.investor_name?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [investorReports, investorReportTemplates, searchText]);

  const reportData = mapApiInvestorReportsGroupedToTableData(sortedReports);

  return (
    <div className='mt-4'>
      <GenericTable
        className='w-full mb-2'
        tableName='Investor Clients'
        data={reportData}
        columns={columnsForClientsViewV2(true)}
        sortBy={sortBy}
        sort={sort}
        sortFunction={sortForInvestorReportingTableGroupedData(sort)}
        recordsPerPage={recordsPerPage}
        selectedPage={selectedPage}
        onPageSelect={onPageSelect}
        onSelectRowsChange={onSelectRowsChange}
        onRowClick={(row) => {
          const reportId =
            row.investorReports.length > 0
              ? (row.investorReports[0].id as number)
              : undefined;
          const isPendingReport = row.investorReports.length === 0;
          const url = generateURLForReportDetails(
            '',
            row.client_id,
            row.firm_id,
            row.user_id,
            reportId,
            isPendingReport ? row.investorReportTemplates[0].id : undefined,
            false
          );
          navigate(url);
        }}
      />
    </div>
  );
}
