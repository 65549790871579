import { Button } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export function InvestorReportCardButton({
  label,
  icon,
  onClick,
  'data-testid': testId,
  color,
  disabled,
}: {
  label: string;
  icon: IconDefinition;
  onClick: () => void;
  'data-testid'?: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'ghost'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | undefined;
  disabled?: boolean;
}) {
  return (
    <div className='pt-3'>
      <Button
        color={color}
        size='sm'
        style={{
          borderRadius: '0.6rem',
          textTransform: 'none',
          pointerEvents: disabled ? 'none' : 'auto',
          minHeight: 'fit-content',
        }}
        className='mr-2 mb-2 text-white py-3'
        onClick={() => onClick()}
      >
        <FontAwesomeIcon
          color='#d3d3d3'
          icon={icon}
          data-testid={testId}
          size='1x'
          className='pr-2'
        />
        {label}
      </Button>
    </div>
  );
}
