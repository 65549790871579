import { useNavigate } from 'react-router-dom';
import { faEye, faLock } from '@fortawesome/free-solid-svg-icons';
import {
  InvestorReportsDetailsData,
  findTemplateForReport,
  getCardTitleColor,
} from '@/pages/InvestorReporting/utils/investorReports';
import { Badge, Button } from 'react-daisyui';
import { useMemo, useState, useEffect } from 'react';
import {
  Client,
  InvestorReportCardDTO,
  InvestorReportTemplateDTO,
  File,
  InvestorReportDTO,
} from '@types';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { useRollbar } from '@rollbar/react';
import { InvestorReportAnswer } from '@/pages/InvestorReporting/components/InvestorReportCards/InvestorReportAnswer';
import { Spinner } from '@/components/Spinner';
import { ToastContainer } from 'react-toastify';
import RequestBuilder from '@/pages/InvestorReporting/components/modals/RequestBuilder';
import { GenericModal } from '@/components/Modal';
// eslint-disable-next-line max-len
import { InvestorReportCardButton } from '@/pages/InvestorReporting/components/InvestorReportCards/InvestorReportCardButton';
import { alertErrorMessage } from '@/utils/alerts';
import { FilePreviewModal } from '@/components/FileBrowser/modals';
import { fetchUrl, processDownloadUrl } from '@/utils/teamsFileDownload';
import FrequencySelector from '@/pages/InvestorReporting/components/FrequencySelector';
import PendingTemplates from '@/pages/InvestorReporting/components/PendingTemplates';
import useLogEvent from '@/utils/logEvent';
import { ReportStatuses } from '../../../../../../shared/constants';

const finalCardStatus = [ReportStatuses.closed, ReportStatuses.deleted];

export function InvestorReportsDetailedInvestorView({
  clientId,
  investorReportsData,
  refreshData,
  refreshTemplates,
  investorReportTemplatesData,
  isInPastUpdatesMode,
  pendingReportId,
}: {
  clientId: number;
  investorReportsData: InvestorReportsDetailsData;
  refreshData: (id: number) => Promise<void>;
  refreshTemplates: () => Promise<void>;
  investorReportTemplatesData: InvestorReportTemplateDTO[];
  isInPastUpdatesMode?: boolean;
  pendingReportId?: number;
}) {
  const navigate = useNavigate();
  const rollbar = useRollbar();
  const { auth }: IUserContext = useUserContext();
  const { logEvent } = useLogEvent();
  const [reportId, setReportId] = useState<number | undefined>(
    investorReportsData.selected_investor_report?.id
  );
  const [cardToAnswer, setCardToAnswer] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [previewFile, setPreviewFile] = useState<boolean>(false);
  const [fileItem, setFileItem] = useState<File | undefined>(undefined);

  const [isSaving, setIsSaving] = useState(false);
  const [isDataRefreshed, setIsDataRefreshed] = useState(false);

  const [editTemplate, setEditTemplate] = useState<
    InvestorReportTemplateDTO | undefined
  >(undefined);

  const enabledFrequencies = useMemo(
    () => investorReportsData.investor_reports.map((rep) => rep.frequency),
    [investorReportsData.investor_reports]
  );

  const report = useMemo(() => {
    if (reportId) {
      const selectedReport = investorReportsData.investor_reports.find(
        (rep) => rep.id === reportId
      );
      const template = findTemplateForReport(
        investorReportTemplatesData,
        selectedReport as InvestorReportDTO
      );
      setEditTemplate(template);
      return selectedReport;
    }
    return undefined;
  }, [
    investorReportsData.investor_reports,
    reportId,
    investorReportTemplatesData,
  ]);

  const onChangeFrequency = (frequency: string) => {
    setCardToAnswer(undefined);
    setReportId(
      investorReportsData.investor_reports.find(
        (rep) => rep.frequency === frequency
      )?.id || undefined
    );
  };

  const isReportComplete = useMemo(() => {
    if (report) {
      const isReportNotClosed = report.status !== ReportStatuses.closed;
      const allCardAnswered =
        report.investor_report_cards?.every(
          (card) =>
            card.status === ReportStatuses.completed ||
            card.status === ReportStatuses.closed
        ) || false;
      return isReportNotClosed && allCardAnswered;
    }
    return false;
  }, [report]);

  useEffect(() => {
    if (isDataRefreshed && isReportComplete) {
      setIsDataRefreshed(false);
    }
  }, [isDataRefreshed, isReportComplete]);

  const downloadFile = (file: File) => {
    fetchUrl(file.id, file.teamId)
      .then(async (url) => {
        await processDownloadUrl(url, file.name);
        await logEvent(file, clientId, auth?.role as string);
      })
      .catch((err) => rollbar.log(err as Error));
  };

  const setFileToPreview = (value: File) => {
    setPreviewFile(true);
    setFileItem(value);
  };

  const onHandleCardUpdate = async () => {
    await refreshData(cardToAnswer?.investor_report_id as number);
    setCardToAnswer(undefined);
    setIsDataRefreshed(true);
  };

  const selectCard = (card: InvestorReportCardDTO) => {
    setCardToAnswer(undefined);
    setTimeout(() => {
      setCardToAnswer(card);
    }, 50);
  };

  const processFileError = () => {
    alertErrorMessage(
      'This file has been updated. Please wait a few minutes and refresh your page to access the latest version.'
    );
  };

  if (!report) {
    if (!pendingReportId) {
      return <span>No report</span>;
    }
    return (
      <PendingTemplates
        pendingTemplateId={pendingReportId}
        templates={investorReportTemplatesData}
        onSave={async () => refreshTemplates()}
      />
    );
  }

  return (
    <div className='flex'>
      <ToastContainer />
      <div
        className={`${isSaving ? 'spinner-container' : 'hidden'}`}
        id='spinner-total'
        data-testid='spinner-investor-report-details'
      >
        <Spinner className='spinner' />
      </div>
      <div className='w-[50%]'>
        <div className='p-3'>
          <span className='ml-2 font-bold text-xl'>
            {isInPastUpdatesMode ? 'Requests' : 'Latest Requests'}
          </span>
        </div>
        <div className='p-3'>
          <span className='ml-2 font-bold text-lg'>
            {investorReportsData.investor_reports[0].client?.client_name}
          </span>
        </div>
        {!isInPastUpdatesMode ? (
          <div className='pl-4 flex'>
            <FrequencySelector
              selectedFrequency={report.frequency}
              enabledFrequencies={enabledFrequencies}
              onChangeFrequency={onChangeFrequency}
            />
          </div>
        ) : null}

        <div className='flex'>
          <div className='p-4'>
            {isInPastUpdatesMode ? 'Period:' : 'Latest Period:'}
            <span className='ml-2 font-bold'>{report.period}</span>
          </div>
          {!isInPastUpdatesMode ? (
            <div>
              <Button
                size='xs'
                className='btn-accent mt-4'
                data-testid='send-update-button'
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={() => {
                  navigate(
                    `/i-reporting?clientName=${encodeURIComponent(
                      investorReportsData.investor_reports[0].client
                        ?.client_name || ''
                    )}&showAllReports=true`
                  );
                }}
              >
                View Past Updates
              </Button>
            </div>
          ) : null}
        </div>

        <div className='flex flex-wrap w-full'>
          {report.investor_report_cards?.map((card) => (
            <div
              key={`card-${card.title}`}
              className={`my-2 mx-2 shadow card rounded-lg 
              w-[45%] justify-center bg-gradient-to-r p-[1px] from-[#00a089] to-[#ff0080]`}
            >
              <div
                key={`card-${card.title}`}
                className='bg-[#1b1b1c] shadow-inner rounded h-full w-full content-center'
              >
                <div className='mt-4 mx-4 text-center '>
                  <span className='font-bold'>{card.title}</span>
                  <div>
                    <span className='font-bold text-sm'>
                      Status:
                      <Badge
                        color={getCardTitleColor(card.status)}
                        size='md'
                        className='ml-2'
                      >
                        {card.status}
                      </Badge>
                    </span>
                  </div>
                  <div className='flex justify-center my-2'>
                    {card.status === ReportStatuses.closed &&
                    !finalCardStatus.includes(report.status) ? (
                      <InvestorReportCardButton
                        label='Closed'
                        icon={faLock}
                        color='error'
                        onClick={() => selectCard(card)}
                        disabled
                        data-testid={`reopen-${card.id as number}`}
                      />
                    ) : null}
                    {card.status === ReportStatuses.completed ||
                    card.status === ReportStatuses.closed ? (
                      <InvestorReportCardButton
                        label='View Response'
                        icon={faEye}
                        color='accent'
                        onClick={() => selectCard(card)}
                        data-testid={`view-response-${card.id as number}`}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {cardToAnswer ? (
        <GenericModal isOpen onClose={() => {}}>
          <InvestorReportAnswer
            investorReportsCard={cardToAnswer}
            client={report.client as Client}
            setSpinner={(value) => {
              setIsSaving(value);
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onCardUpdated={() => onHandleCardUpdate()}
            setFileToPreview={setFileToPreview}
            isFullWidth
          />
        </GenericModal>
      ) : null}
      {previewFile && fileItem ? (
        <FilePreviewModal
          isOpen={previewFile}
          fileItem={fileItem}
          onClose={() => {
            setPreviewFile(false);
          }}
          onDownload={() => downloadFile(fileItem)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onError={() => processFileError()}
        />
      ) : null}
      {!isInPastUpdatesMode ? (
        <div className='w-[50%]'>
          <RequestBuilder
            onSave={async () => refreshTemplates()}
            editTemplate={editTemplate}
          />
        </div>
      ) : null}
    </div>
  );
}
