import { useEffect, useState } from 'react';
import { Client } from '@types';
import { GenericWizardSteps } from '@/components/GenericWizard/GenericWizard';
import Select, { SingleValue } from 'react-select';

interface SelectOptions {
  id: number;
  label: string;
  company_name?: string;
}

interface SelectUserStepProps extends GenericWizardSteps {
  clients: Client[];
  disableNext: (value: boolean) => void;
  updateClient: (value: Client | undefined) => void;
  clientIds: number[];
}

export default function SelectClientStep({
  clients,
  disableNext,
  updateClient,
  clientIds,
}: SelectUserStepProps) {
  const [searchMap, setSearchMap] = useState<SelectOptions[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<
    SingleValue<SelectOptions> | undefined
  >();
  useEffect(() => {
    disableNext(!setSelectedClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, disableNext]);

  useEffect(() => {
    const initialMap: SelectOptions[] = clients
      .filter((c) => clientIds.includes(c.id))
      .map((client) => ({
        id: client.id,
        label: client.client_name,
        company_name: client.client_name,
      }));
    setSearchMap(initialMap);
  }, [clients, clientIds]);

  const filterClients = (newQuery: string) => {
    setSearchQuery(newQuery);
    const filteredOptions: SelectOptions[] = clients
      .filter((client) =>
        client.client_name.toLowerCase().includes(newQuery.toLowerCase())
      )
      .filter((client) => clientIds.includes(client.id))
      .map((client) => ({
        id: client.id,
        label: client.client_name,
        company_name: client.client_name,
      }));
    setSearchMap(filteredOptions);
  };

  return (
    <div title='Select User'>
      <h1 className='text-lg font-bold'>Select Client</h1>
      <br />
      <div>Search existing clients by name</div>
      <div data-testid='select-user-add'>
        <Select
          className='react-select-container border-accent mb-2'
          classNamePrefix='react-select'
          placeholder='Search clients ...'
          closeMenuOnSelect
          isClearable
          options={searchMap}
          inputValue={searchQuery}
          value={selectedClient}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onInputChange={filterClients}
          onChange={(value) => {
            setSelectedClient(value);
            if (value) {
              updateClient({
                id: value.id,
                client_name: value.company_name,
              } as Client);
            } else {
              updateClient(undefined);
            }
            setSearchMap([]);
          }}
          filterOption={() => true}
          onFocus={() => setSearchMap([])}
        />
      </div>
      <br />
    </div>
  );
}
